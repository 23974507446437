import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';
import { ExperienceForm } from '@/Experience-Form';

const StyledExperienceFeedback = styled.div`
    padding: 10em 1em 4em;
    a {
        color: ${colors.blueLight};
    }
    p {
        margin: 1em 0em;
    }

    button {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        margin: 0 auto;
        max-width: 620px;
        padding: 8em 1em 2em;
        .buttons {
            max-width: 680px;
            padding: 1em;
            margin: 2em 0em;
            display: flex;
            justify-content: space-around;
            button {
                margin: 2em;
            }
        }
        section {
            display: flex;
            p {
                padding-right: 2em;
                max-width: 35vw;
            }
        }
        button {
            margin: 4em auto 2em;
        }
    }
    @media ${device.laptop} {
        margin: 0 auto;
        max-width: 1340px;
        padding: 4em 1em 0em;
        section {
            display: flex;
            width: 1320px;
            img {
                margin-top: 1em;
                height: 280px;
            }
            p {
                padding-right: 2em;
            }
        }
        button {
            margin: 4em 0em 2em;
        }
    }
`;

const ExperienceFeedback: Page = () => {
    // const blogs = data.allMdx.nodes;
    return (
        <main>
            <DefaultLayout title="NO_DISPLAY" home>
                <Seo
                    title="Health Covid Information | Bloom La Vie Health"
                    description="The Front Line COVID-19 Critical Care Alliance (FLCCC) publishes updates surrounding the most effective prevention & early treatment protocols to counter COVID-19."
                />
                <StyledExperienceFeedback>
                    <ExperienceForm />
                </StyledExperienceFeedback>
            </DefaultLayout>
        </main>
    );
};

export default ExperienceFeedback;
