import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';

const StyledExperienceForm = styled.div`
    padding: 5em 0em 30em;
    position: relative;
    display: flex;
    justify-content: center;
    .alarm-clock {
        transform: scaleX(-1);
        filter: drop-shadow(32px 32px 64px rgba(3, 93, 125, 0.2))
            drop-shadow(-32px -32px 64px rgba(3, 93, 125, 0.2));
        border-radius: 16px;
        height: 85vh;
    }
    textarea {
        border-radius: 6px;
        min-width: 325px;
        margin: 0.5em 0em 0em;
        max-width: 325px;
        min-height: 120px;
    }
    form {
        color: ${colors.white};
        position: absolute;
        margin: 1em;
        display: flex;
        flex-direction: column;
        bottom: 5em;
        padding: 2em 0em;
        min-width: 365px;
        max-width: 370px;
        border-radius: 64px;
        background: ${colors.blueGradient};
        > div {
            margin: 0.5em 1.5em;
        }
        button {
            align-self: start;
            margin: 1.25em 0em 0em 1.25em;
        }
        input {
            margin-top: 0.5em;
        }
        label {
            font-weight: 600;
            display: flex;
            flex-direction: column;
        }
        > h5 {
            text-align: center;
            font-size: 36px;
            margin-bottom: 0.5em;
        }
    }

    @media ${device.tablet} {
        textarea {
            border-radius: 6px;
            min-width: 385px;
            margin: 0.5em 0em 0em;
            max-width: 385px;
            min-height: 110px;
            max-height: 110px;
        }
        padding: 4em 0em 12em;
        .alarm-clock {
            height: 75vh;
        }
        form {
            min-width: 500px;
            top: 8em;
            bottom: 15em;
            padding: 2em;
            min-height: 650px;
        }
    }
    @media ${device.laptop} {
        .alarm-clock {
            height: 85vh;
            width: 75vw;
            border-radius: 64px;
            margin-right: 8em;
        }

        form {
            top: 6em;
            right: 6em;
            padding: 2em;
            min-height: 650px;
        }
    }
`;

export const ExperienceForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledExperienceForm>
            <StaticImage
                className="alarm-clock"
                src="../images/experience.jpg"
                alt="pink alarm clock"
                placeholder="none"
                quality={100}
            />
            <form onSubmit={onSubmit}>
                <h5>Feedback</h5>
                <div>
                    <label>
                        Name *
                        <input
                            type="text"
                            name="feedback-name"
                            id="feedback-name"
                            placeholder="John Doe"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Phone *
                        <input
                            type="tel"
                            name="experience-phone"
                            id="experience-phone"
                            placeholder="(222) 222 2222"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Email address *
                        <input
                            type="email"
                            name="feedback-email"
                            id="feedback-email"
                            placeholder="example@gmail.com"
                        />
                    </label>
                </div>

                <div>
                    <label>
                        Feedback / Notes
                        <textarea></textarea>
                    </label>
                </div>
                <Button disabled={submitState === 'thanks!'} color="white" type="submit">
                    {' '}
                    <span>
                        {' '}
                        {submitState === 'submitting' ? 'Sending message...' : 'Submit Feedback'}
                        {submitState === 'submitted' && 'Thanks!'}
                    </span>{' '}
                    <StaticImage
                        src="../images/svgs/arrow-right-white.svg"
                        placeholder="none"
                        quality={100}
                        alt="white arrow point right"
                    />
                </Button>
            </form>
        </StyledExperienceForm>
    );
};
